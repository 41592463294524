<template>
  <Content>
    <template #contentTitle>
      {{ $t("warehouse.add_shipment") }}
    </template>
    <template #contentBody>
      <a-spin size="large" :spinning="data.loading">
        <a-row>
          <a-col :span="24">
            <a-steps :current="1" style="max-width: 1800px" size="small">
              <a-step :title="$t('warehouse.set_out_plan')" />
              <a-step :title="$t('warehouse.add_shipment')" />
              <a-step class="mr-3" :title="$t('warehouse.confirm_out_plan')" />
            </a-steps>
          </a-col>
        </a-row>

        <a-row class="mt-3" :gutter="[0, 12]">
          <a-col :span="8"
            >{{ $t("warehouse.out_warehouse") }}:
            {{ data.inventoryWarehouseAddress?.warehouseNo }}</a-col
          >
          <a-col :span="16">
            {{ $t("warehouse.warehouse_address") }}:
            {{ getAddress(data.inventoryWarehouseAddress) }}
            <span class="ml-2" v-if="data.inventoryWarehouseAddress">
              {{ $t("logistics.contacts") }}:
              {{ getLinkman(data.inventoryWarehouseAddress) }}
            </span>
          </a-col>
          <a-col :span="8"
            >{{ $t("warehouse.out_warehouse_type") }}:
            {{ $t($enumLangkey("destinationType", data.destinationType)) }}
          </a-col>
          <a-col :span="8"
            v-if=" data.destinationType == destinationTypeEnum.SeWarehouse"
            >{{ $t("warehouse.shipment_not_set") }}:
            {{ $t($enumLangkey("sendoutType", data.sendoutType)) }}
          </a-col>
          <!-- <a-col
            :span="8"
            v-if="
              data.destinationType != destinationTypeEnum.OtherAddress &&
              data.destinationType != destinationTypeEnum.SeWarehouse
            "
            >{{ $t("warehouse.recipients") }}:
            {{ data.address?.linkMan }}
          </a-col>
          <a-col
            :span="8"
            v-if="
              data.destinationType != destinationTypeEnum.OtherAddress &&
              data.destinationType != destinationTypeEnum.SeWarehouse
            "
            >{{ $t("warehouse.phone") }}: +{{ data.address?.phoneCountryCode }}
            {{ data.address?.linkManPhone }}
          </a-col> -->
          <a-col
            :span="8"
            v-if="data.destinationType == destinationTypeEnum.FBA"
            >{{ $t("warehouse.fba_warehouse_code") }}:
            {{ data.targetAddress?.name }}</a-col
          >
          <a-col
            :span="8"
            v-if="data.destinationType == destinationTypeEnum.mercadolibre"
            >{{ $t("warehouse.mercado_libre_warehouse_code") }}:
            {{ data.targetAddress?.name }}</a-col
          >

          <a-col
            :span="16"
            v-if="
              data.destinationType == destinationTypeEnum.FBA ||
              data.destinationType == destinationTypeEnum.mercadolibre
            "
          >
            {{ $t("logistics.destination_address") }}:
            {{ getAddress(data.targetAddress) }}
          </a-col>

          <a-col
            :span="16"
            v-if="data.destinationType == destinationTypeEnum.OtherAddress"
          >
            {{ $t("logistics.destination_address") }}:
            {{ getAddress(data.address) }}
            <span class="ml-2" v-if="data.address">
              {{ $t("logistics.contacts") }}:
              {{ getLinkman(data.address) }}
            </span>
          </a-col>
        </a-row>
        <a-row
          v-if="data.destinationType == destinationTypeEnum.SeWarehouse"
          :gutter="[0, 12]"
          class="mt-2"
        >
          <a-col
            :span="8"
            v-if="data.destinationType == destinationTypeEnum.SeWarehouse"
            >{{ $t("warehouse.se_warehouse_code") }}:
            {{ data.targetAddress?.warehouseNo }}</a-col
          >
          <a-col :span="16">
            {{ $t("logistics.destination_address") }}:
            {{ getAddress(data.targetAddress) }}
            <span class="ml-2">
              {{ $t("logistics.contacts") }}:
              {{ getLinkman(data.targetAddress) }}
            </span>
          </a-col>
        </a-row>

        <a-button class="mt-2 mb-1" @click="handleShowShipmentAddModal">
          {{ $t("warehouse.add_shipment") }}
        </a-button>

        <a-table
          :columns="columns"
          :data-source="data.list"
          :pagination="false"
          size="small"
          :loading="data.loading"
          @expand="handleGetRowDetail"
          :rowKey="
            (record, index) => {
              return index;
            }
          "
        >
          <template #box_amount="{ record }">
            {{ record.boxCount?.actual }}
          </template>

          <template #shipment_volume="{ record }">
            {{ cmCubicToM(record.volume?.actual) }}
            <span v-if="record.volume?.actual">m<sup>3</sup></span>
          </template>

          <template #shipment_weight="{ record }">
            {{ gToKg(record.weight?.actual) }}
            <span v-if="record.weight?.actual">kg</span>
          </template>

          <template #remove="{ record }">
            <a-popconfirm
              :title="$t('common.are_you_sure')"
              @confirm="handleRemove(record)"
            >
              <a-button size="small">
                {{ $t("warehouse.remove_out_shipment") }}
              </a-button>
            </a-popconfirm>
          </template>

          <template #footer>
            <a-row :gutter="[12, 12]">
              <a-col
                >{{ $t("common.total") }}:
                {{
                  $t("warehouse.p0_outbound_shipments", [data.total.shipment])
                }}</a-col
              >
              <a-col
                >{{ data.total.box ?? "-" }}{{ $t("warehouse.box") }}</a-col
              >
              <a-col v-if="data.total.volume"
                >{{ cmCubicToM(data.total.volume) }}
                <span>m<sup>3</sup></span>
              </a-col>
              <a-col v-if="data.total.weight">
                {{ gToKg(data.total.weight) }}
                <span>kg</span>
              </a-col>
            </a-row>
          </template>
          <template #expandedRowRender="{ record }">
            <a-table
              :columns="innerColumns"
              :loading="record.loading"
              size="small"
              :data-source="record?.innerData"
              :pagination="false"
              :rowKey="
                (record, index) => {
                  return index;
                }
              "
            >
              <template #out_data="{ record }">
                {{ record.checkBoxSize?.length }}x
                {{ record.checkBoxSize?.width }}x
                {{ record.checkBoxSize?.height }}cm |
                {{ cmCubicToM(record.checkBoxSize?.volume) }} m<sup>3</sup>
                <div>{{ gToKg(record.checkBoxSize?.weight) }}kg</div>
              </template>
            </a-table>
          </template>
        </a-table>
        <a-row class="mt-5" type="flex" justify="center">
          <a-col>
            <a-button @click="handleLastStep">
              {{ $t("common.back_step") }}</a-button
            >
          </a-col>
          <a-col>
            <a-button class="ml-5" type="primary" @click="handleShowPlanModal">
              {{ $t("common.next_step") }}</a-button
            >
          </a-col>
        </a-row>
      </a-spin>

      <!-- 添加货件 -->
      <a-modal
        width="850px"
        v-model:visible="shipmentAddModal.visible"
        :title="
          $t('warehouse.add_shipment') +
          '(' +
          data.inventoryWarehouseAddress?.warehouseNo +
          ')'
        "
        :confirm-loading="shipmentAddModal.loading"
        :maskClosable="false"
        :okButtonProps="{ style: { display: 'none' } }"
        :cancelButtonProps="{ style: { display: 'none' } }"
        :centered="true"
        :footer="null"
      >
        <a-spin size="large" :spinning="shipmentAddModal.loading">
          <a-row>
            <a-col :span="12">
              <a-input-group compact class="mb-2">
                <a-select
                  v-model:value="shipmentAddModal.searchType"
                  style="min-width: 150px"
                >
                  <a-select-option value="name">{{
                    $t("warehouse.shipment_name")
                  }}</a-select-option>
                  <a-select-option value="seShipmentNo">{{
                    $t("warehouse.se_shipment_no")
                  }}</a-select-option>
                  <a-select-option value="inPlanNo">{{
                    $t("warehouse.in_warehouse_reservation_no")
                  }}</a-select-option>
                  <a-select-option value="canScanedBoxNo">{{
                    $t("warehouse.identifiable_box_number")
                  }}</a-select-option>
                  <a-select-option value="boxNo">{{
                    $t("warehouse.box_id")
                  }}</a-select-option>
                </a-select>
                <a-input-search
                  :enter-button="$t('common.query')"
                  style="width: 250px"
                  @search="handleSearchShipment"
                  v-model:value="shipmentAddModal.searchValue"
                />
              </a-input-group>
            </a-col>
          </a-row>

          <a-table
            :data-source="shipmentAddModal.list"
            :columns="columnsShipmentAdd"
            :scroll="{ x: true, y: 500 }"
            :pagination="false"
            size="small"
            :rowKey="
              (record, index) => {
                return index;
              }
            "
          >
            <template #checkAllTitle>
              <a-checkbox
                v-model:checked="shipmentAddModal.checked"
                @change="handleCheckAll"
              ></a-checkbox>
            </template>
            <template #check="{ record }">
              <a-checkbox
                :disabled="record.isExist"
                v-model:checked="record.checked"
                @change="handleChangeCheck(record)"
              ></a-checkbox>
            </template>

            <template #box_amount="{ record }">
              {{ record.boxCount?.actual }}
            </template>

            <template #total_volume="{ record }">
              {{ cmCubicToM(record.volume?.actual) }} m<sup>3</sup>
            </template>

            <template #total_weight="{ record }">
              {{ gToKg(record.weight?.actual) }}
              kg
            </template>
            <template #operate="{ record }">
              <a-button disabled size="small" v-if="record.isExist">
                {{ $t("common.added") }}</a-button
              >
              <a-button size="small" @click="handleAdd(record)" v-else>
                {{ $t("common.add") }}</a-button
              >
            </template>
          </a-table>
          <a-row type="flex" justify="center" class="mt-3">
            <a-col>
              <CPager
                @handlePage="handlePage"
                :page-data="shipmentAddModal.pageData"
                :showLessItems="true"
                :showQuickJumper="false"
              ></CPager>
            </a-col>
          </a-row>
          <a-row type="flex" justify="end" class="mt-3">
            <a-col
              ><a-button @click="shipmentAddModal.visible = false">{{
                $t("common.close")
              }}</a-button></a-col
            >
            <a-col
              ><a-button
                @click="handleAddBatch"
                type="primary"
                class="ml-3"
                :disabled="
                  !shipmentAddModal.selectedShipment ||
                  shipmentAddModal.selectedShipment?.length == 0
                "
                >{{ $t("warehouse.batch_add") }}</a-button
              >
              <span v-show="shipmentAddModal.selectedShipment?.length > 0">
                (
                {{
                  $t("warehouse.p0_shipment_have_been_selected", [
                    shipmentAddModal.selectedShipment?.length,
                  ])
                }}
                )
              </span>
            </a-col>
          </a-row>
        </a-spin>
      </a-modal>

      <!-- 添加出库计划的名称 -->
      <a-modal
        v-model:visible="planNameModal.visible"
        :title="$t('warehouse.add_plan_name')"
        :footer="null"
        :centered="true"
      >
        <a-spin :spinning="planNameModal.loading">
          <div>
            <input type="text" class="display-none" />
            <a-row type="flex" justify="center" align="middle">
              <a-col>
                <p class="height-100">
                  {{ $t("warehouse.please_input_plan_name") }}
                </p>
              </a-col>
            </a-row>
            <a-row type="flex" justify="center" align="middle">
              <a-col :span="15">
                <a-input
                  :maxlength="64"
                  v-model:value="planNameModal.planName"
                  required="true"
                  ref="refInputAddPlanName"
                  @focus="handleFocusSelectValue($event)"
                  @pressEnter="handleCreatePlan"
                />
              </a-col>
            </a-row>
          </div>
          <a-row class="mt-4 mb-1" type="flex" justify="center" align="middle">
            <a-col class="mr-4">
              <a-button @click="planNameModal.visible = false">{{
                $t("common.cancel")
              }}</a-button>
            </a-col>
            <a-col>
              <a-button type="primary" @click="handleCreatePlan">{{
                $t("warehouse.created_plan")
              }}</a-button>
            </a-col>
          </a-row>
        </a-spin>
      </a-modal>
    </template>
  </Content>
</template>

<script>
import { useI18n } from "vue-i18n/index";
import {
  defineComponent,
  reactive,
  onMounted,
  onActivated,
  ref,
  watchEffect,
} from "vue";
import {
  Row,
  Col,
  Spin,
  Table,
  Input,
  Select,
  Button,
  Form,
  Space,
  Steps,
  message,
  Popconfirm,
  Modal,
  Checkbox,
} from "ant-design-vue";
import Content from "../../components/Content.vue";
import CPager from "../../components/CPager.vue";
import {
  getShipmentList,
  getCreateShipmentDetailList,
} from "../../../api/modules/transit/outPlan/plan";
import {} from "../../../api/modules/common/index";
import {
  destinationType as destinationTypeEnum,
  deliveryWay as deliveryWayEnum,
} from "../../../enum/enum.json";
import {
  getLinkman,
  getAddressByLanguageV2,
  cmCubicToM,
  gToKg,
  kgToG,
  createAutoPlanName,
} from "../../../utils/general";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { useStore } from "vuex";
import localPrint from "../../../utils/localPrint";
import { useTab } from "../../../hooks/tabs/index";
import {
  setObjectSessionStorage,
  getObjectSessionStorage,
  removeObjectSessionStorage,
} from "../../../utils/store";
import { transitOutPlanKeys } from "../../../consts/index";

export default defineComponent({
  name: "transit_out_plan_add_shipment",
  components: {
    Content,
    CPager,
    ARow: Row,
    ACol: Col,
    ATable: Table,
    AInput: Input,
    AInputSearch: Input.Search,
    AInputGroup: Input.Group,
    ASelect: Select,
    AButton: Button,
    ASelectOption: Select.Option,
    AFormItem: Form.Item,
    AForm: Form,
    ASpin: Spin,
    ASpace: Space,
    ASteps: Steps,
    AStep: Steps.Step,
    APopconfirm: Popconfirm,
    AModal: Modal,
    ACheckbox: Checkbox,
  },
  setup() {
    const router = useRouter();
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });
    const refForm = ref();
    const { delVisitedRoute, visitedRoutes } = useTab();

    const data = reactive({
      loading: false,
      planName: null,
      warehouseId: null, //库存仓库id
      inventoryWarehouseAddress: null, //库存仓库地址对象
      destinationType: null, //出库类型
      toWarehouseId: null, //目标仓库代码
      targetAddress: null,
      address: null,
      sendoutType:null,//为谁发货

      list: [],
      total: {
        shipment: 0,
        box: 0,
        volume: 0,
        weight: 0,
      },
    });

    const dropDownData = reactive({});

    watchEffect(() => {
      data.total.shipment = data.list?.length;
      data.total.box = 0;
      data.total.volume = 0;
      data.total.weight = 0;
      data.list?.forEach((x) => {
        data.total.box = data.total.box + x.boxCount?.actual * 1;
        data.total.volume = data.total.volume + x.volume?.actual * 1;
        data.total.weight = data.total.weight + x.weight?.actual * 1;
      });
    });

    const getAddress = (address) => {
      if (!address) {
        return;
      }
      return getAddressByLanguageV2(address, getters.language);
    };

    const handleGetRowDetail = (expanded, record) => {
      if (
        expanded &&
        record?.id &&
        (!record?.innerData || record?.innerData?.length == 0)
      ) {
        record.loading = true;
        getCreateShipmentDetailList({ shipmentId: record.id })
          .then(({ result }) => {
            if (Array.isArray(result)) {
              record.innerData = result;
            } else {
              record.innerData = [];
            }
            record.loading = false;
          })
          .catch(() => {
            record.loading = false;
          });
      }
    };

    const handleRemove = (record) => {
      data.list = data.list?.filter((x) => x.id != record.id);
    };

    const columns = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        fixed: "left",
        width: 60,
      },
      {
        title: () => vueI18n.t("warehouse.shipment_name"),
        dataIndex: "name",
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.se_shipment_number"),
        dataIndex: "seShipmentNo",
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.box_amount"),
        slots: {
          customRender: "box_amount",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.shipment_volume"),
        slots: {
          customRender: "shipment_volume",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.shipment_weight"),
        slots: {
          customRender: "shipment_weight",
        },
        width: 100,
      },
      {
        title: "",
        slots: {
          customRender: "remove",
        },
        width: 100,
      },
    ];

    const innerColumns = [
      {
        title: () => vueI18n.t("warehouse.serial_number"),
        customRender: ({ text, record, index }) => {
          return index + 1;
        },
        fixed: "left",
        width: 60,
      },
      {
        title: () => vueI18n.t("warehouse.identifiable_box_number"),
        dataIndex: "canScanedBoxNo",
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.box_id"),
        dataIndex: "boxNo",
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.out_data"),
        slots: {
          customRender: "out_data",
        },
        width: 100,
      },
    ];

    //----------------------添加出库货件 start-------------------------------------
    const shipmentAddModal = reactive({
      loading: false,
      visible: false,

      checked: false,
      searchValue: "",
      searchType: "name",
      list: [],
      selectedShipment: [], //已选择
      pageData: {
        currentIndex: 1,
        skipCount: 0,
        totalCount: 0,
        maxResultCount: 10,
      },
    });

    const handleShowShipmentAddModal = (record) => {
      shipmentAddModal.visible = true;
      shipmentAddModal.checked = false;
      shipmentAddModal.selectedShipment = [];
      handleSearchShipment();
    };

    const handlePage = async (pageData) => {
      shipmentAddModal.pageData.skipCount = pageData.skipCount;
      shipmentAddModal.pageData.maxResultCount = pageData.maxResultCount;
      shipmentAddModal.checked = false;
      shipmentAddModal.selectedShipment = [];
      funcSearchShipment();
    };

    const handleSearchShipment = () => {
      shipmentAddModal.pageData.skipCount = 0;
      shipmentAddModal.pageData.currentIndex = 1;
      funcSearchShipment();
    };

    const funcSearchShipment = () => {
      let parameter = {
        [shipmentAddModal.searchType]: shipmentAddModal.searchValue,
        ...shipmentAddModal.pageData,
        warehouseId: data.warehouseId,
      };
      shipmentAddModal.loading = true;
      getShipmentList(parameter)
        .then(({ result }) => {
          if (result) {
            let { items = [], totalCount = 0 } = result;
            let tempArr = items;
            tempArr.forEach((x) => {
              x.isExist = data.list.findIndex((i) => i?.id == x?.id) >= 0;
              x.checked = x.isExist;
            });
            shipmentAddModal.list = tempArr;
            shipmentAddModal.pageData.totalCount = totalCount * 1;
          }
          shipmentAddModal.loading = false;
        })
        .catch(() => {
          shipmentAddModal.loading = false;
        });
    };

    const handleCheckAll = (e) => {
      let checked = e?.target?.checked;
      shipmentAddModal.list.forEach((x) => {
        if (!x.isExist) {
          x.checked = checked;
        }
      });
      if (checked) {
        shipmentAddModal.selectedShipment = shipmentAddModal.list.filter(
          (x) => !x.isExist
        );
      } else {
        shipmentAddModal.selectedShipment = [];
      }
    };

    const handleChangeCheck = (record) => {
      if (record.checked) {
        shipmentAddModal.selectedShipment.push(record);
      } else {
        shipmentAddModal.selectedShipment =
          shipmentAddModal.selectedShipment.filter((x) => x.checked);
      }
    };

    const handleAdd = (record) => {
      record.isExist = true;
      record.checked = true;
      shipmentAddModal.selectedShipment =
        shipmentAddModal.selectedShipment?.filter((x) => x.id != record.id);
      data.list.push(record);
      message.success(vueI18n.t("common.succeed"));
    };

    const handleAddBatch = () => {
      shipmentAddModal.selectedShipment.forEach((x) => (x.isExist = true));
      data.list.push(...shipmentAddModal.selectedShipment);
      shipmentAddModal.selectedShipment = [];
      shipmentAddModal.checked = false;
      message.success(vueI18n.t("common.succeed"));
    };

    const columnsShipmentAdd = [
      {
        slots: {
          title: "checkAllTitle",
          customRender: "check",
        },
        fixed: "left",
        width: 50,
      },
      {
        dataIndex: "name",
        title: () => vueI18n.t("warehouse.shipment_name"),
        width: 100,
      },
      {
        dataIndex: "seShipmentNo",
        title: () => vueI18n.t("warehouse.se_shipment_number"),
        width: 100,
      },
      {
        dataIndex: "columnsShipmentAddTable3",
        title: () => vueI18n.t("warehouse.box_amount"),
        slots: {
          customRender: "box_amount",
        },
        width: 100,
      },
      {
        title: () => vueI18n.t("warehouse.total_volume"),
        width: 90,
        slots: {
          customRender: "total_volume",
        },
      },
      {
        title: () => vueI18n.t("warehouse.total_weight"),
        width: 90,
        slots: {
          customRender: "total_weight",
        },
      },
      {
        title: "",
        width: 50,
        slots: {
          customRender: "operate",
        },
      },
    ];

    //-----------------------添加出库货件 end------------------------------------

    //-----------------------添加出库计划名称 start------------------------------------
    const planNameModal = reactive({
      loading: false,
      visible: false,
      planName: null,
    });

    const handleCreatePlan = () => {
      if (
        !planNameModal.planName ||
        planNameModal.planName?.trim()?.length == 0
      ) {
        return message.error(
          vueI18n.t("common.p0_is_required", [vueI18n.t("warehouse.plan_name")])
        );
      }
      data.planName = planNameModal.planName;
      setObjectSessionStorage(transitOutPlanKeys.createOutPlanData, data);
      router.push({ name: "transit_out_plan_confirm" });
      delVisitedRoute(router.currentRoute.value);
    };

    const refInputAddPlanName = ref();

    const handleShowPlanModal = () => {
      if (!data.list || data.list?.length == 0) {
        return message.error(
          vueI18n.t("warehouse.please_add_outbound_shipments")
        );
      }

      planNameModal.planName = data.planName
        ? data.planName
        : createAutoPlanName();
      planNameModal.visible = true;
      setTimeout(() => {
        refInputAddPlanName.value?.focus();
      }, 100);
    };

    const handleFocusSelectValue = (event) => {
      event.currentTarget.select();
    };
    //-----------------------添加出库计划名称 end------------------------------------
    const getCacheData = () => {
      let cacheData = getObjectSessionStorage(
        transitOutPlanKeys.createOutPlanData
      );

      if (cacheData) {
        Object.assign(data, cacheData);
      } else {
        delVisitedRoute(router.currentRoute.value);
        router.push({ name: "transit_out_plan_list" });
      }

      if (!data.warehouseId) {
        delVisitedRoute(router.currentRoute.value);
        router.push({ name: "transit_out_plan_list" });
      }
    };

    onBeforeRouteLeave((to, from, next) => {
      const cacheRoutes = [
        "/transit/outplan/create",
        "/transit/outplan/confirm",
      ];
      if (!cacheRoutes.includes(to.path)) {
        let tabsHasOrderRoute =
          visitedRoutes.value.findIndex((x) => cacheRoutes.includes(x.path)) >
          -1;
        if (!tabsHasOrderRoute) {
          removeObjectSessionStorage(transitOutPlanKeys.createOutPlanData);
        }
      }
      next();
    });

    const handleLastStep = () => {
      delVisitedRoute(router.currentRoute.value);
      router.push({ name: "transit_out_plan_create" });
    };

    getCacheData();

    onMounted(() => {});

    onActivated(() => {});

    return {
      data,
      dropDownData,
      refForm,
      destinationTypeEnum,
      deliveryWayEnum,
      columns,
      innerColumns,
      cmCubicToM,
      gToKg,
      kgToG,

      getAddress,
      getLinkman,
      handleGetRowDetail,
      handleRemove,
      handleLastStep,

      shipmentAddModal,
      handleShowShipmentAddModal,
      columnsShipmentAdd,
      handleSearchShipment,
      handleCheckAll,
      handleChangeCheck,
      handleAdd,
      handleAddBatch,
      handlePage,

      planNameModal,
      handleCreatePlan,
      handleShowPlanModal,
      handleFocusSelectValue,
      refInputAddPlanName,
    };
  },
});
</script>

<style lang="less" scoped></style>
